<template>
    <section class="mt-10"><LineChart :chartData="ChartData" :chartOptions="chartOptions" :styles="chartStyles" /></section>
    <section class="mt-5">
        <table class="divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('period', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year_1', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year_2', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year_3', 1) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="monthly_data in ElecMonthlyEndexData" :key="monthly_data">
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ monthly_data.month }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(monthly_data.year_1, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(monthly_data.year_2, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(monthly_data.year_3, 2) }}</td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';

    export default {
        components: {
            LineChart,
        },
        created() {
            this.$store.dispatch('energy_data/fetchElecMonthlyEndexData');
        },
        data() {
            return {
                chartStyles: {
                    height: '400px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [{ gridLines: { display: false }, ticks: { fontSize: 8 } }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        },
        computed: {
            ChartData() {
                return {
                    labels: this.$store.getters['energy_data/electricity_monthly_endex_data'].electricity_labels,
                    datasets: [
                        {
                            label: 'Jaar +1',
                            fill: true,
                            backgroundColor: 'hsla(204, 68%, 58%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#4ca3dd',
                            data: this.$store.getters['energy_data/electricity_monthly_endex_data'].electricity_values_y1,
                        },
                        {
                            label: 'Jaar +2',
                            fill: true,
                            backgroundColor: 'hsla(168, 55%, 77%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#A3E4D7',
                            data: this.$store.getters['energy_data/electricity_monthly_endex_data'].electricity_values_y2,
                        },
                        {
                            label: 'Jaar +3',
                            fill: true,
                            backgroundColor: 'hsla(282, 44%, 47%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#8E44AD',
                            data: this.$store.getters['energy_data/electricity_monthly_endex_data'].electricity_values_y3,
                        },
                    ],
                };
            },
            ElecMonthlyEndexData() {
                return this.$store.getters['energy_data/electricity_monthly_endex_data'].table_data;
            },
        },
    };
</script>
